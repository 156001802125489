<template>
  <!-- not used -->
  <LoadingPage v-if="loaded" />
  <div v-else>
    <back-button />
    <h5 class="font-weight-light mb-4">
      <button
        class="button-primary float-right mb-2"
        @click="trackSearch(log.last_mile_tracking_number)"
        title="Tracking"
      >
        <i class="fas fa-chevron-circle-right mr-1"></i
        >{{ $t("ordersListPage.toTrackingPage") }}
      </button>
    </h5>

    <div class="card-deck">
      <!-- order -->

      <div class="card bg-light border-white rounded">
        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ log.type.toUpperCase().replace("_", " ") }}</strong>
          </div>
          <div class="col">
            <strong>{{ log.status.toUpperCase().replace("_", " ") }}</strong>
          </div>
        </div>
        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.orderNumber") }}:</strong>
          </div>
          <div class="col">
            <router-link :to="`/orders/${log.order_id}`" class="text-secondary">
              {{ log.order_number }}
            </router-link>
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.message") }}:</strong>
          </div>
          <div class="col">{{ log.message }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.tracking") }}:</strong>
          </div>
          <div class="col">{{ log.last_mile_tracking_number }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("currency") }}:</strong>
          </div>
          <div class="col">{{ log.currency }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.estimatedTax") }}:</strong>
          </div>
          <div class="col">{{ log.charged_amount }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.realTax") }}:</strong>
          </div>
          <div class="col">{{ log.real_tax }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.expectedTax") }}:</strong>
          </div>
          <div class="col">{{ log.expected_tax }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.productsValue") }}:</strong>
          </div>
          <div class="col">{{ log.products_value }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.incoterm") }}:</strong>
          </div>
          <div class="col">{{ log.incoterm }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.customerName") }}:</strong>
          </div>
          <div class="col">{{ log.customer_full_name }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.customerCountry") }}:</strong>
          </div>
          <div class="col">{{ log.customer_country }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("DutiesAndTaxesViewPage.creationDate") }}:</strong>
          </div>
          <div class="col">
            {{ formateDate(log.volume_creation, log.volume_creation) }}
          </div>
        </div>
      </div>
      <!-- ./order -->
    </div>
  </div>
</template>

<script>
import BackButton from "../../components/generic/BackButton";
import TrackingAlertLogService from "../../services/TrackingAlertLogService";
import NProgress from "nprogress";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const trackingAlertLogService = new TrackingAlertLogService();

export default {
  name: "OrdersView",

  components: { BackButton, LoadingPage },
  data: () => {
    return {
      loaded: true,
      measurementSystem: "metric",
      log: {
        attachment: null,
        customer_country: null,
        customer_full_name: null,
        barcode: null,
        charged_amount: null,
        checkpoint_id: null,
        comment: null,
        created_at: "",
        currency: "",
        date_iso: null,
        expected_tax: null,
        freight_value: null,
        incoterm: "",
        last_mile_number: null,
        last_mile_tracking_number: null,
        message: null,
        order_data: {},
        order_id: null,
        order_number: "",
        products_value: "",
        real_tax: null,
        status: "",
        tax_id: "",
        title: null,
        type: "",
        volume_creation: null,
      },
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      has_insurance: false,
    };
  },
  created() {
    this.loadLog();
  },
  methods: {
    loadLog() {
      trackingAlertLogService
        .getLog(this.$route.params.id)
        .then((response) => {
          const log = response.data.data;
          this.log = log;
          this.loaded = false;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push("/");
          }
          this.$helpers.toast(error.message, "danger");
          NProgress.done();
        });
    },

    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear()
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        //check the last trackingBaseUrl is a "/" and remove it to avoid router bugs like (...)/trackings/tracking making it like (...)/trackings?trackingNumber=tracking_number
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(this.trackingBaseUrl + "?trackingNumber=" + traking_number);
        return;
      }
      location.replace(this.trackingBaseUrl + "404");
    },
  },
};
</script>

<style scoped>
.text-left tbody > tr > td {
  text-align: left !important;
}

.volume-new-dimension {
  border-top: 1px solid var(--gray);
  padding-top: 4px;
}
</style>
