import HttpService from "./HttpService";

export default class TrackingAlertLogService {
  constructor() {
    this.httpService = HttpService;
  }
  /**
   * Get logs
   *
   * @param data
   * @returns {Promise}
   */
  getLogs(offset, params = {}) {
    if (params.limit > 0) {
      offset = (offset - 1) * params.limit;
      return this.httpService.get(`/v1/tracking_alert_logs?&offset=${offset}`, {
        params: params,
      });
    } else {
      offset = (offset - 1) * 20;
      return this.httpService.get(
        "/v1/tracking_alert_logs?limit=20&offset=" + offset,
        {
          params: params,
        }
      );
    }
  }
  /**
   * Get order
   *
   * @param {*} log_id
   * @returns {Promise}
   */
  downloadPaymentCerti(log_id) {
    return this.httpService.put(
      "/v1/tracking_alert_logs/" + log_id + "/payment_label"
    );
  }

  /**
   * Get order
   *
   * @param {*} id
   * @returns {Promise}
   */
  getLog(id = null) {
    return this.httpService.get("/v1/tracking_alert_logs/" + id);
  }
}
